<script lang="ts" setup>
  import type { CButtonProps } from './CButton.types'

  withDefaults(defineProps<CButtonProps>(), {
    darkMode: false,
    disabled: false,
    fluid: false,
    size: 'md',
    tabIndex: 0,
    type: 'button',
    variant: 'primary'
  })

  function handleClick(event: Event) {
    const target = event.target as HTMLElement
    target.blur()
  }
</script>

<template>
  <button
    :class="{
      'btn-primary': variant === 'primary',
      'btn-secondary': variant === 'secondary',
      'btn-tertiary': variant === 'tertiary',
      'btn-ghost': variant === 'ghost',
      'btn-small': size === 'sm',
      'btn-medium': size === 'md',
      'btn-large': size === 'lg',
      'dark-mode': darkMode,
      'fluid': fluid
    }"
    :disabled
    :tabindex="tabIndex"
    :type
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
  button {
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;

    // Typography
    font-family: var(--font-family-base);
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    padding-left: 24px;
    padding-right: 24px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  // Variants
  // Sizes
  .btn-small {
    font-size: 14px;
    height: 40px;
  }

  .btn-medium {
    font-size: 16px;
    height: 48px;
  }

  .btn-large {
    font-size: 18px;
    height: 64px;
  }

  .fluid {
    width: 100%;
  }

  // BTN Primary
  button.btn-primary {
    background-color: var(--color-base-red);
    color: var(--color-base-white);

    border: none;
    outline: 3px solid transparent;

    &:focus {
      background: var(--color-base-white);
      color: var(--color-base-black);
      outline: 3px solid var(--color-focus-outline-light);
    }

    &:active {
      background-color: var(--color-secondary-red-dark);
      color: var(--color-base-white);
      outline: 3px solid transparent;
    }

    &:disabled {
      background-color: var(--color-link-disabled);
      outline: 3px solid transparent;
    }
  }

  // BTN Secondary
  button.btn-secondary {
    background-color: var(--color-base-white);
    color: var(--color-base-red);

    border: none;
    outline: 1px solid var(--color-base-red);

    &:focus {
      background: var(--color-base-white);
      color: var(--color-base-black);
      outline: 3px solid var(--color-focus-outline-light);
    }

    &:active {
      background-color: var(--color-base-red);
      color: var(--color-base-white);
      outline: 1px solid transparent;
    }

    &:disabled {
      background-color: var(--color-secondary-paper-lightest);
      color: var(--color-link-disabled);
      outline: 1px solid var(--color-link-disabled);
    }
  }

  // BTN Tertiary
  button.btn-tertiary {
    background-color: transparent;
    color: var(--color-base-black);

    border: none;
    outline: 1px solid var(--color-stroke-light);

    &:focus {
      background: var(--color-base-white);
      color: var(--color-base-black);
      outline: 3px solid var(--color-focus-outline-light);
    }

    &:active {
      background-color: var(--color-base-red);
      color: var(--color-base-white);
      outline: 1px solid transparent;
    }

    &:disabled {
      background-color: var(--color-link-disabled);
      color: var(--color-base-white);
      outline: 1px solid transparent;
    }
  }

  button.btn-ghost {
    background-color: transparent;
    color: var(--color-base-red);

    border: none;
    outline: 3px solid transparent;

    &:focus {
      background-color: var(--color-base-white);
      color: var(--color-base-black);
      outline: 3px solid var(--color-focus-outline-light);
      text-decoration: none;
    }

    &:active {
      background-color: transparent;
      color: var(--color-base-red);
      outline: 3px solid transparent;
      font-weight: 700;
      text-decoration: underline;
    }

    &:disabled {
      background-color: transparent;
      color: var(--color-text-muted);
      outline: 3px solid transparent;
      font-weight: 700;
      text-decoration: none;
    }
  }

  // Dark mode
  button.btn-primary.dark-mode {
    color: var(--color-base-black);
    background-color: var(--color-base-white);
    outline: solid 3px transparent;

    &:focus {
      background-color: transparent;
      color: var(--color-base-white);
      outline: solid 3px var(--color-focus-outline-dark);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.7);
      color: var(--color-base-black);
      outline: solid 3px transparent;
    }

    &:disabled {
      background-color: var(--color-link-inverse-disabled);
      color: var(--color-base-black);
      outline: solid 3px transparent;
    }
  }

  button.btn-secondary.dark-mode {
    background-color: transparent;
    color: var(--color-base-white);
    outline: solid 1px var(--color-base-white);

    &:focus {
      background-color: transparent;
      color: var(--color-base-white);
      outline: solid 3px var(--color-focus-outline-dark);
    }

    &:active {
      background-color: var(--color-base-white);
      color: var(--color-base-black);
      outline: solid 1px transparent;
    }

    &:disabled {
      background-color: var(--color-link-inverse-disabled);
      color: var(--color-base-black);
      outline: solid 1px var(--color-link-inverse-disabled);
    }
  }

  button.btn-tertiary.dark-mode {
    background-color: transparent;
    color: var(--color-base-white);
    outline: solid 3px transparent;

    &:focus {
      background-color: transparent;
      outline: solid 3px var(--color-focus-outline-dark);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.35);
      outline: solid 3px transparent;
    }

    &:disabled {
      background-color: var(--color-link-disabled);
      outline: solid 3px transparent;
    }
  }

  button.btn-ghost.dark-mode {
    background-color: transparent;
    color: var(--color-base-white);
    outline: solid 3px transparent;

    &:focus {
      background-color: transparent;
      outline: solid 3px var(--color-focus-outline-dark);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.35);
      outline: solid 3px transparent;
      font-weight: 700;
      text-decoration: underline;
    }

    &:disabled {
      background-color: transparent;
      color: var(--color-link-inverse-disabled);
      outline: solid 3px transparent;
      font-weight: 700;
      text-decoration: none;
    }
  }

  /* Apply hover styling to devices that support (i.e. non-mobile) */
  @media (hover: hover) {
    // BTN Primary
    button.btn-primary:not(:disabled):hover {
      background-color: var(--color-secondary-red-hover);
      color: var(--color-base-white);
      outline: 3px solid transparent;
    }

    // BTN Secondary
    button.btn-secondary:not(:disabled):hover {
      background-color: var(--color-secondary-red-lightest);
      color: var(--color-base-red);
      outline: 1px solid var(--color-base-red);
    }

    // BTN Tertiary
    button.btn-tertiary:not(:disabled):hover {
      background-color: var(--color-secondary-paper-light);
      color: var(--color-base-black);
      outline: 1px solid var(--color-stroke-medium);
    }

    button.btn-ghost:not(:disabled):hover {
      background-color: transparent;
      color: var(--color-secondary-red-dark);
      outline: 3px solid transparent;
    }

    // Dark mode
    button.btn-primary.dark-mode:not(:disabled):hover {
      background-color: var(--color-secondary-green-lightest);
      color: var(--color-base-black);
      outline: solid 3px transparent;
    }

    button.btn-secondary.dark-mode:not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--color-base-white);
      outline: solid 1px var(--color-base-white);
    }

    button.btn-tertiary.dark-mode:not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--color-base-black);
      outline: solid 3px transparent;
    }

    button.btn-ghost.dark-mode:not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--color-base-black);
      outline: solid 3px transparent;
    }
  }
</style>
