export default defineNuxtPlugin((nuxtApp) => {
  // Check if the site is loaded within page with showBodyOnly query parameter
  let showBodyOnly = false
  let noPanel = false

  if (import.meta.client) {
    // Check if query parameter is set to show only the body
    const urlSearchParams = new URLSearchParams(window.location.search)
    showBodyOnly = urlSearchParams.has('showBodyOnly', 'true')
    noPanel = urlSearchParams.has('noPanel', 'true')
  } else {
    // Check if query parameter is set to show only the body on the server side
    const headers = useRequestHeaders(['host'])
    if (nuxtApp.ssrContext?.url) {
      const url = new URL(headers.host + nuxtApp.ssrContext.url, `https://${headers.host}`)
      const urlSearchParams = new URLSearchParams(url.search)
      showBodyOnly = urlSearchParams.has('showBodyOnly')
      noPanel = urlSearchParams.has('noPanel')
    }
  }

  // Inject the result into the context as $showBodyOnly and $noPanel
  nuxtApp.provide('showBodyOnly', showBodyOnly)
  nuxtApp.provide('noPanel', noPanel)
})
