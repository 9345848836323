import type InputText from 'primevue/inputtext'
import { useScript } from '@unhead/vue'
import type { RestaurantGroupOrderingItem } from '~/types/api/group-bookings'
declare global {
  interface HTMLIFrameElement {
    iFrameResizer?: any
  }
}
/**
 * @description Handles loading the Obee Widget when a restaurant is selected
 */
export function useObeeScript(selectedRestaurant: Ref<RestaurantGroupOrderingItem | null>) {
  const obeeScript = ref()
  const count = ref(1)

  function initObee() {
    if (!selectedRestaurant.value) {
      return
    }

    destroyObee()

    obeeScript.value = useScript({
      src: 'https://obee.com.au/common/js/dynamic/iframe.js',
      async: true,
      key: 'obee-' + count.value + '-' + selectedRestaurant.value.bookingProvider?.configValue1
    })

    count.value++
  }

  function destroyObee() {
    if (obeeScript.value) {
      obeeScript.value.remove()
    }
  }

  onUnmounted(() => {
    destroyObee()
  })

  return {
    initObee,
    destroyObee
  }
}

/**
 * @description Handles loading the Now Book It Widget when a restaurant is selected
 */
export function useNowBookItScript(selectedRestaurant: Ref<RestaurantGroupOrderingItem | null>) {
  const nowBookItScript = ref()
  const count = ref(1)
  const nowBookItIframeSrc = ref('')

  const iframeResizerApi = ref<any>(null)

  function initNowBookIt() {
    if (!selectedRestaurant.value) {
      return
    }

    destroyNowBookIt()
    nowBookItIframeSrc.value = `https://bookings.nowbookit.com/?accountid=${selectedRestaurant.value.bookingProvider?.configValue1}&venueid=${selectedRestaurant.value.bookingProvider?.configValue2}&theme=light&colors=hex,ed1c24&bg=transparent&banner=hidden`

    // set the now book it iframe resizer script
    nowBookItScript.value = useScript({
      src: 'https://plugins.nowbookit.com/iframe-resizer-build/bundle.js',
      async: true,
      // setting crossorigin to false will revert the defaults to avoid CORS issues
      crossorigin: false,
      key: 'nbi-' + count.value + '-' + selectedRestaurant.value.bookingProvider?.configValue2
    })

    nowBookItScript.value.onLoaded(() => {
      // Script ready. Set the iframeResizerApi value from the iframe so the iFrameResizer functions can be called
      const iframe = document.querySelector('iframe[data-id="nbi-widget"]') as HTMLIFrameElement
      if (iframe && iframe.iFrameResizer) {
        iframeResizerApi.value = iframe.iFrameResizer
      }
    })

    nowBookItScript.value.onError((error: any) => {
      // script error!
      console.warn('Now Book It iframe resizer error:', error)
    })

    count.value++
  }

  function destroyNowBookIt() {
    if (iframeResizerApi.value) {
      iframeResizerApi.value.removeListeners()
      iframeResizerApi.value.close()
    }
    if (nowBookItScript.value) {
      nowBookItScript.value.remove()
    }
  }

  onUnmounted(() => {
    destroyNowBookIt()
  })

  return {
    initNowBookIt,
    destroyNowBookIt,
    nowBookItIframeSrc: readonly(nowBookItIframeSrc)
  }
}

type IsDropdownMenuOpen = Ref<boolean>
type ResultItemRefs = Ref<Record<string, Element | null>>
type FilteredResults = ComputedRef<RestaurantGroupOrderingItem[]>
type InputElement = Ref<InstanceType<typeof InputText> | null>

/**
 * @description Keyboard event handlers for the search input
 */
export function useInputHandlers({
  isDropdownMenuOpen,
  resultItemRefs
}: {
  isDropdownMenuOpen: IsDropdownMenuOpen
  resultItemRefs: ResultItemRefs
}) {
  /**
   * @description When the user presses down,
   * focus the first item in the dropdown menu
   */
  function inputKeydownDownHandler() {
    if (isDropdownMenuOpen.value && resultItemRefs.value[0]) {
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses esc,
   * close the dropdown menu
   */
  function inputKeydownEscHandler() {
    isDropdownMenuOpen.value = false
  }

  return {
    inputKeydownDownHandler,
    inputKeydownEscHandler
  }
}

/**
 * @description Keyboard event handlers for the dropdown menu items
 */
export function useDropdownItemHandlers({
  filteredResults,
  inputElement,
  isDropdownMenuOpen
}: {
  filteredResults: FilteredResults
  inputElement: InputElement
  isDropdownMenuOpen: IsDropdownMenuOpen
}) {
  const resultItemRefs = ref<Record<string, Element | null>>({})

  /**
   * @description When the user presses tab when the last result is focused,
   * focus the first item
   */
  function dropdownItemKeydownTabHandler(event: Event, index: number) {
    if (index === filteredResults.value.length - 1) {
      event.preventDefault()
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses shift + tab when the first result is focused,
   * focus the last item
   */
  function dropdownItemKeydownShiftTabHandler(event: Event, index: number) {
    if (index === 0) {
      event.preventDefault()
      ;(resultItemRefs.value[filteredResults.value.length - 1] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses up arrow,
   * focus the previous result
   */
  function dropdownItemKeydownUpArrowHandler(index: number) {
    if (index === 0) {
      ;(resultItemRefs.value[filteredResults.value.length - 1] as HTMLLIElement).focus()
    } else {
      ;(resultItemRefs.value[index - 1] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses down arrow,
   * focus the next result
   */
  function dropdownItemKeydownDownArrowHandler(index: number) {
    if (index === filteredResults.value.length - 1) {
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    } else {
      ;(resultItemRefs.value[index + 1] as HTMLLIElement).focus()
    }
  }

  function dropdownItemKeydownEscapeHandler() {
    isDropdownMenuOpen.value = false

    if (inputElement.value) {
      inputElement.value.$el.focus()
    }
  }

  return {
    resultItemRefs,
    dropdownItemKeydownShiftTabHandler,
    dropdownItemKeydownTabHandler,
    dropdownItemKeydownUpArrowHandler,
    dropdownItemKeydownDownArrowHandler,
    dropdownItemKeydownEscapeHandler
  }
}
